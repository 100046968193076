<script lang="ts" setup>
const {
  data: claims,
  isFetching,
  onSearchTermChanged,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage
} = useClaimFiltering();
</script>

<template>
  <LayoutTemplate>
    <List
      :is-fetching="isFetching"
      :is-fetching-next-page="isFetchingNextPage"
      :on-search-term-changed="onSearchTermChanged"
      :fetch-next-page="
        () => {
          if (hasNextPage) fetchNextPage();
        }
      "
      :elements-length="claims.length"
    >
      <ClaimGridItem v-for="claim in claims" v-bind="claim" :key="claim.id" reduced />
    </List>
  </LayoutTemplate>
</template>
